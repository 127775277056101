<template>

    <footer class="mt-20 bg-[#13002e]">
        <h1 class="text-white text-xl text-center p-8">Via Development 2024</h1>
    </footer>
   
</template>
  
  <script>
  export default {
    name: 'TheFoot',
  
  };
  </script>
  