<template>
  <body>
    <TheHead></TheHead>
    <div class=" m-4">
      <section class="m-6 mt-36 max-w-[1200px] mx-auto">
        <h1 class="text-5xl text-white text-center">Via great utilities, you can power the stars.</h1>
        <p class="text-2xl text-alt text-center mt-6 max-w-[900px] mx-auto font-[Gothamb]">Via Development is a group that develops Discord bots to be a reliable and stylish addition to your Discord server</p>
        <a href="https://discord.gg/UWk4UQku88" class="text-white mx-auto text-center block mt-4 text-lg">Join Our Discord Server</a>
      </section>

      <section class="m-6 mt-36 max-w-[1000px] mx-auto" id="projects">
        <div class="flex gap-3 items-baseline" >
          <h1 class="text-3xl text-white">Via Projects</h1>
          <p class="text-lg text-white font-[Gothamb]">What have we made?</p>
        </div>
        
        <div class="flex justify-between mt-3 sm:flex-wrap mx-auto">
          <div class="w-[480px] bg-secondary p-6 rounded-lg text-white">
            <div class="flex justify-between items-baseline">
              <h1 class="text-2xl">Mr Poll</h1>
              <p class="text-md font-[Gothamb] text-alt">30k+ Servers</p>
            </div>
            <p class="text-lg font-[Gothamb] mt-2">Adding polls and suggestions to your server in the stylish way.</p>
            <button @click="goToPoll" class="w-full bg-button rounded-lg p-2 mt-2">Add</button>
          </div>

          <div class="w-[480px] bg-secondary p-6 rounded-lg text-white">
            <div class="flex justify-between items-baseline">
              <h1 class="text-2xl">REPP</h1>
              <p class="text-md font-[Gothamb] text-alt">&lt;1k Servers</p>
            </div>
            <p class="text-lg font-[Gothamb] mt-2">REPP is a bot that shows you the good and the bad, giving members a reputation score.</p>
            <button @click="goToRepp" class="w-full bg-button rounded-lg p-2 mt-2">Add</button>
          </div>


        </div>

      </section>

      <section class="m-6 mt-28 max-w-[1000px] mx-auto" id="news">
        <div class="flex gap-3 items-baseline" >
          <h1 class="text-3xl text-white">Via News</h1>
          <p class="text-lg text-white font-[Gothamb]">What's going on?</p>
        </div>
        
        <div class="mt-3">
          <div class="w-full bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/steve.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">The ViaDev website!</h1>
                <p class="text-alt">Steve - 7th Sep 2024</p>
              </div>
            </div>
          </div>

          
        </div>

      </section>

      <section class="m-6 mt-28 max-w-[1000px] mx-auto" id="team">
        <div class="flex gap-3 items-baseline" >
          <h1 class="text-3xl text-white">Via Team</h1>
        </div>
        
        <div class="mt-3 grid grid-cols-2 gap-3">
          <div class="bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/steve.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">Steve</h1>
                <p class="text-alt">Founder</p>
              </div>
            </div>
          </div>

          <div class=" bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/littie.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">Littie</h1>
                <p class="text-alt">Developer</p>
              </div>
            </div>
          </div>

          

          <div class=" bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/muffin.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">Muffin</h1>
                <p class="text-alt">Administrator</p>
              </div>
            </div>
          </div>

          <div class=" bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/conos.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">Conos</h1>
                <p class="text-alt">Developer</p>
              </div>
            </div>
          </div>

          <div class=" bg-secondary p-6 rounded-lg">
            <div class="flex gap-3 ">
              <img src="../assets/drx.png" class=" w-16 rounded-lg">
              <div class="">
                <h1 class="text-white text-xl">Drx</h1>
                <p class="text-alt">Developer</p>
              </div>
            </div>
          </div>
          
        </div>

      </section>



    </div>
    <TheFoot></TheFoot>
  </body>
</template>

<script>


import TheHead from '@/components/TheHead.vue'
import TheFoot from '@/components/TheFoot.vue'

export default {
  name: 'HomeView',
  components: {
    TheHead,TheFoot
  },
  methods: {
    goToPoll() {
      window.location.href = 'https://mrpoll.dev';
    },

    goToRepp() {
      window.location.href = 'https://top.gg/bot/1262261395152568381';
    }
  }
}
</script>
