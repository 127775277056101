<template>
  <nav>
    <div class="m-6 flex">
      <ul class="flex gap-3 text-xl">
        <li><a href="/" class="text-white inline-block relative mx-4">Via Development</a></li>
        <li><a href="#projects" class="text-accent inline-block relative mx-4">Projects</a></li>
        <li><a href="#news" class="text-accent inline-block relative mx-4">News</a></li>
        <li><a href="#team" class="text-accent inline-block relative mx-4">Via Team</a></li>

      </ul>


    </div>
  </nav>
    

</template>

<script>
export default {
  name: 'TheHead',

};
</script>

<style>
body {
  @apply bg-primary
}
</style>